import { atom } from "jotai";
import { TokenDetails } from "@/types/token";
import { atomWithStorage } from "jotai/utils";

export type TokenListTab = "default" | "meme";

export const tokenListTabAtom = atom<TokenListTab>("default");

export const fullTokenListAtom = atom<TokenDetails[]>([]);

export const strictTokenListAtom = atom((get) => {
  const fullList = get(fullTokenListAtom);
  return fullList.filter((token) => token.verified);
});

export const fullTokenMapAtom = atom((get) => {
  const fullList = get(fullTokenListAtom);
  return fullList.reduce(
    (acc, token) => {
      acc[token.type] = token;
      return acc;
    },
    {} as Record<string, TokenDetails>,
  );
});

export const trendingTokenListAtom = atom<TokenDetails[]>([]);

export const memeTokenListAtom = atom<TokenDetails[]>([]);

export const pinTokenListAtom = atomWithStorage<TokenDetails[]>(
  "pinTokenList",
  [],
);

export const pinTokenSetAtom = atom<Set<string>>((get) => {
  const pinTokens = get(pinTokenListAtom);
  return new Set(pinTokens.map((token) => token.type));
});

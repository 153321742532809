import { agInfoTabAtom, priceChartLoadedAtom } from "@/atoms/aggregator.atom";
import { useAtom, useSetAtom } from "jotai";
import { INFO_TABS } from "./constants";
import tw from "@/utils/twmerge";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";
import { useMemo } from "react";
import RefreshButton from "./RefreshButton";
import ReactGa from "@/utils/ga";

function MInfoTabs() {
  const [selectedTab, setSelectedTab] = useAtom(agInfoTabAtom);
  const setPriceChartLoaded = useSetAtom(priceChartLoadedAtom);

  const selectedItem = useMemo(() => {
    return INFO_TABS.find((t) => t.key === selectedTab);
  }, [selectedTab]);

  return (
    <>
      <div className="grid grid-cols-3 bg-skin-alt p-0.5 rounded-xl">
        {INFO_TABS.map(({ key, shortLabel, tooltip }) => (
          <button
            key={key}
            className={tw(
              "flex items-center justify-center gap-1 rounded-xl p-3",
              selectedTab === key
                ? "bg-black-60 text-white"
                : "bg-transparent text-gray-100",
            )}
            onClick={() => {
              setSelectedTab(key);
              if (key === "price-chart") {
                setPriceChartLoaded(true);
                ReactGa.event("click_price_chart_tab");
              }
            }}
          >
            <span className="text-xs/none">{shortLabel}</span>
            {tooltip && (
              <div onClick={(e) => e.stopPropagation()}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <ICInfoCircle className="w-4 aspect-square" />
                    </TooltipTrigger>
                    <TooltipContentGlow className="w-[16rem]">
                      <span>{tooltip}</span>
                    </TooltipContentGlow>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </button>
        ))}
      </div>

      {selectedItem?.key === "swap-history" && (
        <div className="flex items-center justify-between gap-2">
          <div className="font-semibold text-lg/none">Swap History</div>
          <RefreshButton />
        </div>
      )}
    </>
  );
}

export default MInfoTabs;

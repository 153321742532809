import { useAtomValue } from "jotai";
import HomePage from "./pages/home";
import { isMobileAtom } from "./atoms/layout.atom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/common/Layout";
import { lazy, Suspense } from "react";

const LeaderBoard = lazy(() => import("./pages/leaderboard"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/leaderboard",
        element: (
          <Suspense fallback={null}>
            <LeaderBoard />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  const isMobile = useAtomValue(isMobileAtom);
  return isMobile !== undefined ? <RouterProvider router={router} /> : null;
}

export default App;

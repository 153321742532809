import { agDebugSimulate, agSlippageAtom } from "@/atoms/aggregator.atom";
import {
  _7K_COMMISSION_BPS,
  _7K_COMMISSION_PARTNER,
  _7K_CONFIG,
  _7K_PACKAGE_ID,
  _7K_VAULT,
} from "@/constants/_7k";
import { suiClient } from "@/constants/suiClient";
import { SorSwapResponse } from "@/types/swapInfo";
import { bcs } from "@mysten/sui/bcs";
import { TransactionObjectArgument } from "@mysten/sui/transactions";
import { isValidSuiAddress, normalizeStructTag } from "@mysten/sui/utils";
import BigNumber from "bignumber.js";
import { getDefaultStore } from "jotai";
import { SuiUtils } from "../sui";
import { getSplitCoinForTx } from "./libs/getSplitCoinForTx";
import { groupSwapRoutes } from "./libs/groupSwapRoutes";
import { swapWithRoute } from "./libs/swapWithRoute";

interface Params {
  sorResponse: SorSwapResponse;
  accountAddress: string;
  /** WARN: must set to true when simulate tx and false to build tx for real execution */
  devInspect?: boolean;
}

const SIMULATE_ACCS: Record<string, string> = {
  "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI":
    "0xac5bceec1b789ff840d7d4e6ce4ce61c90d190a7f8c4f4ddf0bff6ee2413c33c",
  "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN":
    "0xf821d3483fc7725ebafaa5a3d12373d49901bdfce1484f219daa7066a30df77d",
  "0x06864a6f921804860930db6ddbe2e16acdf8504495ea7481637a1c8b9a8fe54b::cetus::CETUS":
    "0xda79a82f67c908b1ed3095887673454226b5da77822898405dc376a1b86bf0f3",
  "0x5d1f47ea69bb0de31c313d7acf89b890dbb8991ea8e03c6c355171f84bb1ba4a::turbos::TURBOS":
    "0x60dd01bc037e2c1ea2aaf02187701f9f4453ba323338d2f2f521957065b0984d",
  "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN":
    "0xf821d3483fc7725ebafaa5a3d12373d49901bdfce1484f219daa7066a30df77d",
};

export const buildTx = async ({
  sorResponse,
  accountAddress: _acc,
  devInspect,
}: Params) => {
  const isSimulate = getDefaultStore().get(agDebugSimulate);
  const accountAddress = isSimulate
    ? SIMULATE_ACCS[normalizeStructTag(sorResponse.tokenIn)] || _acc
    : _acc;
  if (!accountAddress || !sorResponse.routes) return;

  const routes = groupSwapRoutes(sorResponse);

  const splits = routes.map((group) => group[0]?.amount ?? "0");
  const { tx, coinData } = await getSplitCoinForTx(
    accountAddress,
    sorResponse.swapAmountWithDecimal,
    splits,
    normalizeStructTag(sorResponse.tokenIn),
    undefined,
    isSimulate || devInspect,
  );

  const coinObjects: TransactionObjectArgument[] = [];
  await Promise.all(
    routes.map(async (route, index) => {
      const inputCoinObject = coinData[index];
      const coinRes = await swapWithRoute({
        route,
        inputCoinObject,
        currentAccount: accountAddress,
        tx,
      });
      if (coinRes) {
        coinObjects.push(coinRes);
      }
    }),
  );
  if (coinObjects.length > 0) {
    const mergeCoin: any =
      coinObjects.length > 1
        ? SuiUtils.mergeCoins(coinObjects, tx)
        : coinObjects[0];
    const slippage = getDefaultStore().get(agSlippageAtom);
    const minReceived = new BigNumber(1)
      .minus(slippage.toBigNumber())
      .multipliedBy(sorResponse.returnAmountWithDecimal)
      .toFixed(0);

    const urlParams = new URLSearchParams(window.location.search);
    const commissionPartner = isSimulate
      ? urlParams.get("debug_commission_partner") || _7K_COMMISSION_PARTNER
      : _7K_COMMISSION_PARTNER;
    const commissionBps = isSimulate
      ? urlParams.get("debug_commission_bps") || _7K_COMMISSION_BPS
      : _7K_COMMISSION_BPS;

    const [comm_partner_result] = isValidSuiAddress(commissionPartner)
      ? tx.moveCall({
          target: "0x1::option::some",
          typeArguments: [`address`],
          arguments: [tx.pure.address(commissionPartner)],
        })
      : tx.moveCall({
          target: "0x1::option::none",
          typeArguments: [`address`],
          arguments: [],
        });

    tx.moveCall({
      target: `${_7K_PACKAGE_ID}::settle::settle`,
      typeArguments: [sorResponse.tokenIn, sorResponse.tokenOut],
      arguments: [
        tx.object(_7K_CONFIG),
        tx.object(_7K_VAULT),
        tx.pure.u64(sorResponse.swapAmountWithDecimal),
        mergeCoin,
        tx.pure.u64(minReceived),
        tx.pure.u64(sorResponse.returnAmountWithDecimal),
        comm_partner_result,
        tx.pure.u64(Number(commissionBps)),
      ],
    });
    tx.transferObjects([mergeCoin], tx.pure.address(accountAddress));
  }

  if (!isSimulate || devInspect) {
    return tx;
  }

  console.log(tx);
  const inspectResult = await suiClient.devInspectTransactionBlock({
    transactionBlock: tx,
    sender: accountAddress,
  });
  const coins: any[] = [];
  let receive = 0;
  inspectResult.results?.map((r) => {
    r.returnValues?.map((rv) => {
      if (rv[1]?.startsWith("0x2::coin::Coin")) {
        const data = bcs
          .struct("Coin", {
            id: bcs.struct("UID", {
              id: bcs.struct("ID", { bytes: bcs.Address }),
            }),
            balance: bcs.struct("Balance", { value: bcs.U64 }),
          })
          .parse(new Uint8Array(rv[0]));

        const id = rv[1]
          .replace("0x2::coin::Coin", "")
          .replace(">", "")
          .replace("<", "");
        coins.push({
          id,
          data,
        });

        if (
          normalizeStructTag(id) === normalizeStructTag(sorResponse.tokenOut)
        ) {
          receive += +data.balance.value;
        }
      }
    });
  });
  console.log("coins", coins);
  console.log(
    "receive",
    (receive * +sorResponse.returnAmount) /
      +sorResponse.returnAmountWithDecimal,
  );

  console.log("inspectResult buildTx", inspectResult);
  if (inspectResult.error) {
    console.log("inspectResult error", inspectResult.error);
  }
};

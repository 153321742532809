import { ICCopy, ICDrop, ICExport, ICPowerOff } from "@/assets/icons";
import CopyBtn from "@/components/CopyBtn";
import ExplorerAccountLink from "@/components/ExplorerLink/ExplorerAccountLink";
import { useDisconnectWallet } from "@mysten/dapp-kit";
import { formatAddress } from "@mysten/sui/utils";
import type { WalletAccount } from "@mysten/wallet-standard";
import TokenBalances from "./TokenBalances";
import { useAtomValue } from "jotai";
import { isMobileAtom } from "@/atoms/layout.atom";
import TextAmt from "@/components/TextAmt";
import { accountIncentiveAtom } from "@/atoms/account.atom";
import { ICInfoCircle } from "@/assets/icons";
import {
  Tooltip,
  TooltipContentGlow,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/UI/Tooltip";

type Props = {
  currentAccount: WalletAccount;
};

function WalletInfo({ currentAccount }: Props) {
  const { address } = currentAccount;
  const { mutate: disconnectWallet } = useDisconnectWallet();
  const isMobile = useAtomValue(isMobileAtom);
  const incentivePoint = useAtomValue(accountIncentiveAtom);

  return (
    <>
      {!isMobile && (
        <div className="flex items-center justify-between gap-2 text-white">
          <span className="text-sm">{formatAddress(address)}</span>
          <div className="flex items-center gap-2">
            <CopyBtn text={address} className="p-0.5">
              <ICCopy className="w-5 h-auto" />
            </CopyBtn>
            <ExplorerAccountLink account={address} className="p-0.5">
              <ICExport className="w-5 h-auto" />
            </ExplorerAccountLink>
            <button onClick={() => disconnectWallet()} className="p-0.5">
              <ICPowerOff className="w-5 h-auto" />
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-col p-4 rounded-[0.625rem] border border-black bg-black-60">
        <span className="text-sm/none text-gray-100 h-6">Drops</span>
        <div className="flex items-center justify-between gap-4 p-2">
          <ICDrop className="w-6 h-auto" />
          <div className="flex items-center gap-2">
            <TextAmt
              number={incentivePoint.total_point}
              className="font-semibold text-sm/none text-green-80 mt-0.5"
            />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="h-[1.25rem] flex items-center justify-center">
                  <ICInfoCircle className="w-4 aspect-square" />
                </TooltipTrigger>
                <TooltipContentGlow className="w-[18rem]">
                  <div className="flex flex-col gap-4 bg-[#252734]">
                    <div className="flex items-center justify-between gap-2">
                      <span className="font-normal text-xs/none text-gray-100">
                        Beta Launch
                      </span>
                      <TextAmt
                        number={incentivePoint.beta_point}
                        className="font-semibold text-sm/none text-white"
                      />
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      <span className="font-normal text-xs/none text-gray-100">
                        Official Launch
                      </span>
                      <TextAmt
                        number={incentivePoint.official_point}
                        className="font-semibold text-sm/none text-green-80"
                      />
                    </div>
                  </div>
                </TooltipContentGlow>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-0 sm:p-4 rounded-[0.625rem] bg-[#252734] text-gray-100">
        <div className="text-sm h-6">Balances</div>
        <TokenBalances />
      </div>
      {isMobile && (
        <div className="flex flex-col gap-2">
          <CopyBtn
            text={address}
            className="flex items-center justify-between gap-2 px-6 py-3 rounded-2xl bg-black-50"
          >
            <span className="text-sm/none text-gray-100">Copy Address</span>
            <ICCopy className="w-5 h-auto" />
          </CopyBtn>
          <ExplorerAccountLink
            account={address}
            className="flex items-center justify-between gap-2 px-6 py-3 rounded-2xl bg-black-50"
          >
            <span className="text-sm/none text-gray-100">Open in Explorer</span>
            <ICExport className="w-5 h-auto" />
          </ExplorerAccountLink>
          <button
            onClick={() => disconnectWallet()}
            className="flex items-center justify-between gap-2 px-6 py-3 rounded-2xl bg-black-50"
          >
            <span className="text-sm/none text-gray-100">Disconnect</span>
            <ICPowerOff className="w-5 h-auto" />
          </button>
        </div>
      )}
    </>
  );
}

export default WalletInfo;

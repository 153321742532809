import { SorSwapResponse } from "@/types/swapInfo";
import { normalizeTokenType } from "@/utils/token";
import { useQuery } from "@tanstack/react-query";

interface Params {
  tokenInId: string;
  tokenOutId: string;
  amountIn: string;
  enabled?: boolean;
  refetchInterval?: number;
}

const SOURCES = [
  "cetus",
  "turbos",
  "deepbook",
  "deepbook_v3",
  "kriya",
  "kriya_v3",
  "flowx",
  "aftermath",
  "suiswap",
  "bluemove",
];

const useAgSor = ({
  tokenInId,
  tokenOutId,
  amountIn,
  enabled,
  refetchInterval,
}: Params) => {
  return useQuery<SorSwapResponse>({
    queryKey: ["agSor", tokenInId, tokenOutId, amountIn],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_AG_API}/quote?amount=${amountIn}&from=${normalizeTokenType(tokenInId)}&to=${normalizeTokenType(tokenOutId)}&sources=${SOURCES}`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch aggregator sor");
      }
      return response.json();
    },
    refetchInterval,
    enabled,
    placeholderData: (prev) => {
      const getKey = (tokens: string[]) =>
        tokens
          .map((t) => normalizeTokenType(t))
          .sort()
          .join("-");
      const key = getKey([tokenInId, tokenOutId]);
      const prevKey = prev ? getKey([prev.tokenIn, prev.tokenOut]) : "";
      if (key !== prevKey || !amountIn || +amountIn === 0) {
        return undefined;
      }
      return prev;
    },
    gcTime: 500,
  });
};

export default useAgSor;

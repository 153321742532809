import { BIG_ZERO } from "@/constants/amount";
import { IncentivePoint } from "@/types/drop";
import { CoinBalance } from "@mysten/sui/client";
import { atom } from "jotai";

export const accountBalanceListAtom = atom<CoinBalance[]>([]);

export const accountBalanceMapAtom = atom((get) => {
  const balances = get(accountBalanceListAtom);
  return balances.reduce(
    (acc, balance) => {
      acc[balance.coinType] = balance.totalBalance;
      return acc;
    },
    {} as Record<string, string>,
  );
});

export const accountIncentiveAtom = atom<IncentivePoint>({
  beta_point: BIG_ZERO,
  official_point: BIG_ZERO,
  total_point: BIG_ZERO,
});

export const accountPriceMapAtom = atom<Record<string, number>>({});
